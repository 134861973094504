import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/priloha_A/" className="sel">
              {" "}
              &gt; Príloha A
            </a>
          </div>
          <div id="page_content">
            <h1 className="uc">
              Príloha A: Typy súborov cookies používané na tejto webovej stránke
            </h1>
            <div className="wysiwyg">
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  I.Cookies/technológiu sledovania zabezpečuje JNTL Consumer Health (Slovakia) s.r.o. (cookies prvej strany)
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  <em>
                    1. Súbory cookies potrebné na základné účely a funkcie
                    webovej stránky
                  </em>
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>&nbsp;</u>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Zdroj:</u> <em>JNTL Consumer Health (Slovakia) s.r.o..</em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Účel:</u>{" "}
                <em>
                  Tieto súbory cookies sú nevyhnutné na správne fungovanie našej
                  stránky. Umožňujú zaručiť bezpečnosť a efektívne zobrazovanie
                  našej stránky.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti:</u>{" "}
                <em>
                  Súbor cookie, ktorý sme nastavili na tento účel, sa
                  automaticky odstráni z vášho zariadenia mesiac po poslednej
                  návšteve našej stránky.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania:</u>{" "}
                <em>
                  Nastavenia prehľadávača môžete zmeniť tak, aby boli súbory
                  cookies povolené alebo zakázané. Keďže spôsob nastavenia je v
                  každom prehľadávači iný, mali by ste konkrétne informácie
                  vyhľadať v ponuke Pomoc vášho prehľadávača.{" "}
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <em>&nbsp;</em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  <em>2. Súbory cookies na vylepšenie webovej stránky</em>{" "}
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>&nbsp;</u>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Zdroj:</u> <em>JNTL Consumer Health (Slovakia) s.r.o.</em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Účel:</u>{" "}
                <em>
                  Tieto súbory cookies umožňujú zlepšenie fungovania a
                  používania našej webovej stránky. Napríklad nám môžu pomáhať
                  zistiť, či váš prehľadávač môže spúšťať malé internetové
                  programy (nazývané „skripty“), ktoré pridávajú funkcie webovej
                  stránky alebo nám umožňujú zapamätať si nejaké vami nastavené
                  predvoľby (napríklad veľkosť písma alebo jazykové nastavenia).
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti:</u>{" "}
                <em>
                  Súbor cookie, ktorý sme nastavili na tento účel, sa
                  automaticky odstráni z vášho zariadenia po zatvorení
                  prehľadávača.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania:</u>{" "}
                <em>
                  Nastavenia prehľadávača môžete zmeniť tak, aby boli súbory
                  cookies povolené alebo zakázané. Keďže spôsob nastavenia je v
                  každom prehľadávači iný, mali by ste konkrétne informácie
                  vyhľadať v ponuke Pomoc vášho prehľadávača.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <br />
                <strong>
                  II. Cookies/technológiu sledovania zabezpečujú poskytovatelia
                  služieb JNTL Consumer Health (Slovakia) s.r.o. a iné spoločnosti
                  (cookies tretích strán)
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <em>
                  <strong>1. Analytické cookies a technológie:</strong> s_fid,
                  s_dl, s_cpm, s_vnum, s_lv, s_vi
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Zdroj:</u>{" "}
                <em>
                  Adobe Analytics (skôr Adobe Site Catalyst &amp; Adobe
                  Omniture){" "}
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Účel:</u>{" "}
                <em>
                  Adobe Analytics používa cookies, aby rozlíšil medzi rôznymi
                  užívateľmi internetových stránok a zistil, čo robia, a na čo
                  sa pozerajú na internetových stránkach. Tieto cookies nám
                  umožňujú prijímať a analyzovať informácie návštevníkov, ako je
                  využitie prehliadačov, počet nových návštevníkov, počet
                  zobrazených stránok za návštevu a reakcie na marketingové
                  aktivity. Adobe Analytics nám umožňuje identifikovať a
                  pochopiť problémy pri vašom pohybe na našich internetových
                  stránkach a dáta zhromažďujeme za účelom zlepšiť stránky pre
                  vás ako zákazníka. Možnosť pozerať sa na tieto informácie nám
                  pomáha identifikovať sekcie, funkcie a produkty na webové
                  stránke, ktoré fungujú dobre a sú užitočné pre vás ako
                  spotrebiteľa, rovnako ako tie, ktoré nefungujú tak dobre. To
                  nám umožňuje zlepšovať vašu užívateľskú skúsenosť. Tieto Adobe
                  cookies nám poskytujú informácie, ktoré nám pomáhajú zlepšovať
                  kvalitu Vám ponúkaných služieb. Údaje zhromaždené spoločnosťou
                  Adobe Analytics môžu vidieť len príslušné tímy v
                  spoločnostiach Kenvue a Adobe. Žiadna časť
                  použitých hodnôt cookies nemôže viesť k osobným údajom
                  návštevníkov.{" "}
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti:</u>{" "}
                <em>
                  s_fid - 2 roky, s_dl - 2 roky, s_cpm - 90 dní, s_vnum - 1 rok,
                  s_lv - 1 rok, s_vi - 5 rokov,{" "}
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania:</u>Môžete si zvoliť odmietnuť cookies
                tu:{" "}
                <a
                  href="http://metrics.cpgconsumer.com/optout.html"
                  target="_blank"
                >
                  http://metrics.cpgconsumer.com/optout.html
                </a>{" "}
                Spoločnosť Kenvue nie je zodpovedná za obsah
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <br />
                &nbsp;
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <em>
                  <strong>2. Analytické súbory cookies a technológie</strong>
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Zdroj:</u>Google Analytics
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Účel:</u>{" "}
                <em>
                  Tieto súbory cookies slúžia na zber informácií o spôsobe
                  používania našej stránky návštevníkmi. Tieto informácie slúžia
                  na kompilovanie výkazov a pomoc pri zlepšovaní stránky. Súbory
                  cookies zbierajú informácie v anonymnej forme, vrátane počtu
                  návštevníkov stránky, stránok, odkiaľ návštevníci na stránku
                  prišli, a stránok, ktoré navštívili.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti:</u>{" "}
                <em>
                  Niektoré súbory cookies nastavené na tento účel sa automaticky
                  odstránia z vášho zariadenia po zatvorení prehľadávača. Iné
                  môžu v počítači ostať až 24 mesiacov od poslednej návštevy
                  stránky.{" "}
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania:</u>{" "}
                <em>
                  Sledovanie prostredníctvom programu Google Analytics môžete
                  zrušiť kliknutím na odkaz:{" "}
                  <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=en-GB"
                    target="_blank"
                  >
                    http://tools.google.com/dlpage/gaoptout?hl=en-GB
                  </a>
                  .{" "}
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </p>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>3. Reklamné súbory cookies a technológie</strong>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Zdroj: </u>
                <em>AppNexus</em>
                <br />
                <u>Účel: </u>
                <em>
                  Tieto súbory cookies slúžia na zbieranie informácií o tom, ako
                  návštevníci používajú našu stránku, aby im bolo na našej
                  stránke alebo iných stránkach možné zobrazovať reklamu, ktorá
                  je viac prispôsobená ich záujmom.
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti</u>:{" "}
                <em>
                  Niektoré súbory cookies vytvorené na tento účel môžu v
                  počítači ostať až 12 mesiacov od poslednej návštevy stránky.
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania</u>:
                <em>
                  Sledovanie prostredníctvom programu AppNexus môžete zrušiť
                  kliknutím na odkaz:{" "}
                  <a
                    href="https://www.appnexus.com/platform-policy#choices"
                    target="_blank"
                  >
                    https://www.appnexus.com/platform-policy#choices{" "}
                  </a>
                  &nbsp;
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  4. Súbory cookies a technológie na zdieľanie obsahu
                </strong>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Zdroj:&nbsp;</u>
                <em>AddThis</em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Účel:&nbsp;</u>
                <em>
                  Tieto súbory cookies a technológie umožňujú zdieľanie stránok
                  a obsahu, ktorý vás na našej stránke zaujíma, prostredníctvom
                  sociálnych sietí a iných webových stránok tretích strán.
                  Spoločnosti, ktoré tieto súbory cookies a technológie
                  poskytujú, môžu vaše informácie zdieľať s tretími osobami
                  alebo ich používať na cielenú reklamu pre vás na iných
                  webových stránkach. Niektoré súbory cookies vytvorené na tento
                  účel môžu v počítači ostať až 24 mesiacov od poslednej
                  návštevy stránky.{" "}
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti</u>:&nbsp;
                <em>
                  Niektoré súbory cookies vytvorené na tento účel môžu v
                  počítači ostať až 24 mesiacov od poslednej návštevy stránky.
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania</u>:
                <em>
                  Sledovanie prostredníctvom programu AddThis môžete zrušiť
                  kliknutím na odkaz:{" "}
                  <a
                    href="https://www.addthis.com/what-we-do/advertisers"
                    target="_blank"
                  >
                    https://www.addthis.com/what-we-do/advertisers
                  </a>
                </em>
              </div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>
                <strong>
                  5.&nbsp;Súbory cookies a technológie funkcií webovej stránky
                </strong>
              </div>
              <div>&nbsp;</div>
              <div>
                <u
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Zdroj:&nbsp;
                </u>
                <em
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  YouTube
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Účel:&nbsp;</u>
                <em>
                  Tieto súbory cookies slúžia na zabezpečenie funkcií našej
                  stránky, ktoré dodávajú tretie strany. Bez týchto súborov
                  cookies by boli niektoré funkcie stránky našim návštevníkom
                  nedostupné. Údaje zozbierané našimi poskytovateľmi služby na
                  tento účel sa môžu anonymne zdieľať s tretími osobami a
                  používať na účely cielenej reklamy.
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti</u>:&nbsp;
                <em>
                  YouTube na tento účel využíva súbory Adobe Flash (alebo „Flash
                  cookies“). Sú vo vašom zariadení uložené natrvalo, ale možno
                  ich odstrániť podľa pokynov na zablokovanie. Iné súbory
                  cookies vytvorené stránkou YouTube na tento účel môžu v
                  počítači ostať až 8 mesiacov od poslednej návštevy našej
                  stránky.
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania</u>:
                <em>
                  Súbory Flash cookies môžete povoliť alebo zakázať pomocou
                  správcu internetových nastavení Adobe na adrese:{" "}
                  <a
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    target="_blank"
                  >
                    http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
                  </a>
                  . Sledovanie prostredníctvom portálu YouTube môžete zrušiť
                  kliknutím na odkaz:{" "}
                  <a
                    href="http://googleads.g.doubleclick.net/ads/preferences/naiv0optout"
                    target="_blank"
                  >
                    http://googleads.g.doubleclick.net/ads/preferences/naiv0optout
                  </a>
                  .{" "}
                </em>
              </div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>
                <strong>6.&nbsp;Bezpečnostné súbory cookies</strong>
              </div>
              <div>&nbsp;</div>
              <div>
                <u
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Zdroj:&nbsp;
                </u>
                <em
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  McAfee
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Účel:&nbsp;</u>
                <em>
                  Tieto súbory cookies slúžia na zvýšenie bezpečnosti našej
                  stránky a na jej ochranu pred škodlivými aktivitami
                  návštevníkov. Údaje zozbierané našimi poskytovateľmi služby na
                  tento účel sa môžu anonymne zdieľať s tretími osobami a
                  používať na účely cielenej reklamy.
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Uplynutie platnosti</u>:&nbsp;
                <em>
                  McAfee na tento účel využíva súbory Adobe Flash (alebo „Flash
                  cookies“). Sú vo vašom zariadení uložené natrvalo, ale možno
                  ich odstrániť podľa pokynov na zablokovanie.{" "}
                </em>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Spôsob zablokovania</u>:
                <em>
                  Súbory Flash cookies môžete povoliť alebo zakázať pomocou
                  správcu internetových nastavení Adobe na adrese:
                  http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html.
                  Prípadne, ak sa chcete dozvedieť viac o postupoch zbierania
                  údajov spoločnosťou McAfee, môžete navštíviť stránku:{" "}
                  <a
                    href="http://www.mcafee.com/us/about/legal/privacy.aspx"
                    target="_blank"
                  >
                    http://www.mcafee.com/us/about/legal/privacy.aspx
                  </a>{" "}
                </em>
              </div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>
                <p>
                  Pozn. Ak odmietnete použitie cookies prvých alebo tretích
                  strán, táto stránka sa nemusí správať tak, ako bola vyvinutá,
                  v dôsledku nedostupnosti cookies.
                </p>
              </div>
              <p>&nbsp;</p>
            </div>
          </div>
          <Footer></Footer>{" "}

        </div>
      </div>
    );
  }
}

export default Page;
